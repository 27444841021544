import React from "react"
import { graphql } from "gatsby"
import Head from "../components/head"
import Layout from "../components/layout"

export const query = graphql`
  query ($slug: String!) {
    markdownRemark (fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
      }
      html
    }
  }
`

export default function Post(props) {
  return (
    <Layout>
      <Head title={props.data.markdownRemark.frontmatter.title} robots="noindex, nofollow" />
      <div className="my-16 sm:my-24 lg:my-32">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-4xl mx-auto mb-8 md:mb-12 lg:mb-16">
            <h1 className="block text-4xl !leading-[1.1] font-serif text-petrol sm:text-5xl lg:text-6xl break-words hyphens-auto">{props.data.markdownRemark.frontmatter.title}</h1>
          </div>
          <div className="prose prose-petrol prose-md md:prose-lg text-petrol prose-headings:text-petrol prose-h1:font-serif prose-h1:font-normal prose-h2:font-serif prose-h2:font-normal prose-h3:font-serif prose-h3:font-normal prose-a:text-petrol prose-blockquote:text-petrol prose-strong:text-petrol mx-auto max-w-4xl mt-8 md:mt-12 lg:mt-16" dangerouslySetInnerHTML={{__html: props.data.markdownRemark.html}}>
          </div>
        </div>
      </div>
    </Layout>
  )
}
